import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './Boosts.css'
import { Body } from '../../components/Body/Body'
import Button from '../../components/Button/Button'
import checkmark from './img/checkmark.svg'
import { useTranslation } from '@plaidev/react-i18n';


const Boosts = ({socket}) => {


    const taskTypes = {
        REFFERAL: useTranslation({
            ru: {
              message: 'Реферал',
            },
            en: {
              message: 'Referral',
            },
            "zh-CN": {
                message: ''
            },
            "tr": {
                message: ''
            },
            ar: {
                message: ''
            },
            es: {
                message: ''
            }
          }),
        SUBSCRIBE: useTranslation({
            ru: {
              message: 'Подписаться',
            },
            en: {
              message: 'Subscribe',
            },
            "zh-CN": {
                message: ''
            },
            "tr": {
                message: ''
            },
            ar: {
                message: ''
            },
            es: {
                message: ''
            }
          }),
    }

    const taskPlatforms = {
        '' : useTranslation({
            ru: {
              message: '',
            },
            en: {
              message: '',
            },
            zh: {
                message: ''
            },
            tr: {
                message: ''
            },
            ar: {
                message: ''
            },
            es: {
                message: ''
            }
          }),
        YOUTUBE : useTranslation({
            ru: {
              message: 'YouTube',
            },
            en: {
              message: 'YouTube',
            },
            zh: {
                message: 'YouTube'
            },
            tr: {
                message: 'YouTube'
            },
            ar: {
                message: 'YouTube'
            },
            es: {
                message: 'YouTube'
            }
          }),
        INSTAGRAM : useTranslation({
            ru: {
              message: 'Instagram',
            },
            en: {
              message: 'Instagram',
            },
            zh: {
                message: 'Instagram'
            },
            tr: {
                message: 'Instagram'
            },
            ar: {
                message: 'Instagram'
            },
            es: {
                message: 'Instagram'
            }
          }),
        TIKTOK : useTranslation({
            ru: {
              message: 'TikTok',
            },
            en: {
              message: 'TikTok',
            },
            zh: {
                message: 'TikTok'
            },
            tr: {
                message: 'TikTok'
            },
            ar: {
                message: 'TikTok'
            },
            es: {
                message: 'TikTok'
            }
          }),
        X : useTranslation({
            ru: {
              message: 'Твиттер (X)',
            },
            en: {
              message: 'Twitter (X)',
            },
            zh: {
                message: '推特 (X)'
            },
            tr: {
                message: 'Twitter (X)'
            },
            ar: {
                message: 'تويتر (X)'
            },
            es: {
                message: 'Twitter (X)'
            }
          }),
        DISCORD: useTranslation({
            ru: {
              message: 'Discord',
            },
            en: {
              message: 'Discord',
            },
            zh: {
                message: 'Discord'
            },
            tr: {
                message: 'Discord'
            },
            ar: {
                message: 'Discord'
            },
            es: {
                message: 'Discord'
            }
          }),
        PARTNERS : useTranslation({
            ru: {
              message: 'Партнеры',
            },
            en: {
              message: 'Partners',
            },
            zh: {
                message: '合作伙伴'
            },
            tr: {
                message: 'Ortaklar'
            },
            ar: {
                message: 'الشركاء'
            },
            es: {
                message: 'Socios'
            }
          }),
        TELEGRAM_CHAT: useTranslation({
            ru: {
              message: 'Телеграм чат',
            },
            en: {
              message: 'Telegram chat',
            },
            zh: {
                message: 'Telegram 聊天'
            },
            tr: {
                message: 'Telegram sohbeti'
            },
            ar: {
                message: 'دردشة برقية'
            },
            es: {
                message: 'Chat de Telegram'
            }
          }),
        TELEGRAM_CHANNEL: useTranslation({
            ru: {
              message: 'Телеграм канал',
            },
            en: {
              message: 'Telegram channel',
            },
            zh: {
                message: 'Telegram 频道'
            },
            tr: {
                message: 'Telegram kanalı'
            },
            ar: {
                message: 'قناة تيليجرام'
            },
            es: {
                message: 'Canal de Telegram'
            }
          }),
    }

    const [tasks, setTasks] = useState([])

    const navigate = useNavigate()
  
    useEffect(() => {
        socket.emit("get_tasks", {tgid: window.Telegram.WebApp.initDataUnsafe.user.id})

        window.Telegram.WebApp.BackButton.show()
        window.Telegram.WebApp.onEvent('backButtonClicked', () => navigate("/"))
        return () => window.Telegram.WebApp.offEvent('backButtonClicked', () => navigate("/"))
    }, [])

    function compare( a, b ) {
      if ( !a.checked ){
        return -1;
      }
      if ( a.checked ){
        return 1;
      }
      return 0;
    }

    socket.on("set_tasks", (v) => {
        setTasks(v.sort(compare))
    })

    const onClick = (v) => {
        if (!v.checked) {
            if (v.type === "REFFERAL") {

            } else {
                setTasks(tasks.map(s => {
                    if (v.id === s.id) {
                        s.checked = true
                    }
                    return s
                }))
                if (v.link.includes("t.me")) {
                    window.Telegram.WebApp.openTelegramLink(v.link)
                } else {
                    window.Telegram.WebApp.openLink(v.link)
                }
                socket.emit("mark_task_checked", { tgid: window.Telegram.WebApp.initDataUnsafe.user.id, taskid: v.id, taskSum: v.spins })
                
            }
        }
    }

  return (
    <Body>
        <div id='boost_main'>
            {
                Object.values(tasks).map(v => {
                    return(
                      <div className={`boost_item_back${(v.checked) ? " done" : ""}`}>
                        <div className={`boost_item${(v.checked) ? " done" : ""}`}>
                            <div className='boost_item_name'>
                                <h4>{taskTypes[v.type].message}</h4>
                                <p>{taskPlatforms[v.platform].message}</p>
                            </div>
                            <div className='boost_item_info'>
                                <p className='boost_item_info_bonus'>+{v.spins}</p>
                                <Button svg={(v.checked) && checkmark} onClick={() => onClick(v)} color={(!v.checked) && 'green'} text='GO' />
                            </div>
                        </div>
                      </div>
                    )
                })
            }
        </div>
    </Body>
  )
}

export default Boosts