import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Confetti from 'react-confetti'
import { formatSI } from 'format-si-prefix'
import './Main.css'
import Button from '../../components/Button/Button'
import useClickOutside from '../../hooks/useClickOutside'
import logo from "./img/DoomCity.png"
import check from "./img/check.svg"
import config from '../../config.js'
import { useTonAddress, useTonConnectUI  } from '@tonconnect/ui-react'


const SpinButton = ({onClick, autospin, setAutospin, disabled}) => {
  return(
    <div id='main_spin_container'>
      <div onClick={onClick} id='main_down_spin'>
        <div className={`main_down_spin_s ${(!disabled) ? "disabled" : ""}`}>
          <div className={`main_down_spin_t ${(!disabled) ? "disabled" : ""}`}>
            <p>SPIN</p>
          </div>
        </div>
      </div>
      <div onClick={() => setAutospin(!autospin)} id='main_spin_autospin'>
        <div>
          { (autospin) && <img src={check} alt='check'/>}
        </div>
        <p>Auto spin</p>
      </div>
    </div>
  )
}

const SimpButton = ({ hasTasks, text, onClick }) => {
  return(
    <div className='main_up_button_container'>
      <div onClick={onClick} className='main_up_button'>
          <div className='main_up_button_s'>
            <div className='main_up_button_f wallet'>
              <div className='main_up_button_t'>
                <div className='main_up_button_a'>
                  <p>{text}</p>
                  </div>
              </div>
            </div>
          </div>
      </div>
      { (hasTasks) && <div className='main_up_button_notf'></div>}
    </div>
  )
}

const Shield = ({text, name, src, onClick, onClose, show}) => {

  const [animate, setAnimate] = useState(false)

  const ref = useClickOutside(onClose);

  useEffect(() => {
    if (show) {
      setTimeout(()=> setAnimate(true), 50)
    } else {
      setAnimate(false)
    }
  }, [show])

  return (
    <>
      <div id='shield_background'>
          <div ref={ref} id='shield_wrap'>
          { (name) && <div id='shield_name_container'>
                    <div id='shield_name_container_s'>
                        <div id='shield_name_container_t'>
                            <div id='shield_name_content'>
                                <h3>{name}</h3>
                            </div>
                        </div>
                    </div>
                </div>}
              <div id='shield_main'>
                  <div className='shield_rivets_sideways'>
                      <div className='main_rivet'></div>
                      <div className='main_rivet'></div>
                      <div className='main_rivet'></div>
                      <div className='main_rivet'></div>
                      <div className='main_rivet'></div>
                  </div>
                  <div id='shield_main_center'>
                      <div id='shield_main_center_up'>
                          <div className='main_rivet'></div>
                          <div className='main_rivet'></div>
                          <div className='main_rivet'></div>
                          <div className='main_rivet'></div>
                          <div className='main_rivet'></div>
                      </div>
                      <div id='shield_main_center_content'>
                          <div id='shield_main_center_content_s'>
                              <div id='shield_main_center_content_t'>
                                <div className={`shield_main_center_content_f ${(animate) ? "show" : "unshow"}`}>
                                  <img src={src} alt='nft' />
                                </div>
                              </div>
                          </div>
                      </div>
                      <div id='shield_main_center_down'>
                          <div className='main_rivet'></div>
                          <div className='main_rivet'></div>
                      </div>
                  </div>
                  <div className='shield_rivets_sideways'>
                      <div className='main_rivet'></div>
                      <div className='main_rivet'></div>
                      <div className='main_rivet'></div>
                      <div className='main_rivet'></div>
                      <div className='main_rivet'></div>
                  </div>
              </div>
              <Button onClick={onClick} text={text} />
          </div>
      </div>
      <Confetti numberOfPieces={1000} gravity={0.04} recycle={false}/>
    </>
  )
}

const AutoShield = ({text, name, awards, onClick, onClose, show}) => {

  const [animate, setAnimate] = useState(false)

  const ref = useClickOutside(onClose);

  useEffect(() => {
    if (show) {
      setTimeout(()=> setAnimate(true), 50)
    } else {
      setAnimate(false)
    }
  }, [show])

  return (
    <>
      <div id='shield_background'>
          <div ref={ref} id='shield_wrap'>
          { (name) && <div id='shield_name_container'>
                    <div id='shield_name_container_s'>
                        <div id='shield_name_container_t'>
                            <div id='shield_name_content'>
                                <h3>{name}</h3>
                            </div>
                        </div>
                    </div>
                </div>}
              <div id='shield_main'>
                  <div className='shield_rivets_sideways'>
                      <div className='main_rivet'></div>
                      <div className='main_rivet'></div>
                      <div className='main_rivet'></div>
                      <div className='main_rivet'></div>
                      <div className='main_rivet'></div>
                  </div>
                  <div id='shield_main_center'>
                      <div id='shield_main_center_up'>
                          <div className='main_rivet'></div>
                          <div className='main_rivet'></div>
                          <div className='main_rivet'></div>
                          <div className='main_rivet'></div>
                          <div className='main_rivet'></div>
                      </div>
                      <div id='shield_main_center_content'>
                          <div id='shield_main_center_content_s'>
                              <div id='shield_main_center_content_t'>
                                <div style={{ gap: "10px", justifyContent: "flex-start", background: "none", height: "250px", overflowY: "auto"}} className={`shield_main_center_content_f ${(animate) ? "show" : "unshow"}`}>
                                  {
                                    Object.values(awards).map(v => {
                                      console.log(awards)
                                      console.log(v)
                                      return (
                                        <div className='shield_item'>
                                          <img src={v.link} alt='item'/>
                                          <p>x{v.count}</p>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                          </div>
                      </div>
                      <div id='shield_main_center_down'>
                          <div className='main_rivet'></div>
                          <div className='main_rivet'></div>
                      </div>
                  </div>
                  <div className='shield_rivets_sideways'>
                      <div className='main_rivet'></div>
                      <div className='main_rivet'></div>
                      <div className='main_rivet'></div>
                      <div className='main_rivet'></div>
                      <div className='main_rivet'></div>
                  </div>
              </div>
              <Button onClick={onClick} text={text} />
          </div>
      </div>
      <Confetti numberOfPieces={1000} gravity={0.04} recycle={false}/>
    </>
  )
}

const icon_width = 41.66
const icon_height = 41.66
const num_icons = 5
const time_per_icon = 100

const Main = ({socket}) => {

  const userWallet = useTonAddress()
  const [_, setConfig] = useTonConnectUI()

  const [score, setScore] = useState("000000")
  const [spins, setSpins] = useState("000")
  const [wallet, setWallet] = useState("")
  const [hasTasks, setHasTasks] = useState(false)
  const [canRoll, setCanRoll] = useState(true)

  const [showNft, setShowNft] = useState(false)
  const [nftUrl, setNftUrl] = useState("")

  const [adLink, setAdLink] = useState("")
  const [autoSpin, setAutoSpin] = useState(false)
  const [autoSpinAwards, setAutoSpinAwards] = useState([])
  const [autoSpinShow, setAutoSpinShow] = useState(false)

  const [tapeImg, setTapeImg] = useState('')
  const [tapePlaces, setTapePlaces] = useState({})
  const [tapeLastName, setTapeLastName] = useState('orange')

  const [showWalletPanel, setShowWalletPanel] = useState(false)

  const [curSpinPosition, setCurSpinPosition] = useState(["green", "green", "green", "green", "green"])

  const navigate = useNavigate()

  const closeWallet = () => setShowWalletPanel(false)

  const walletPanelRef = useClickOutside(closeWallet);

  const buttonRootId = 'ton-connect-button';

  useEffect(() => {
    socket.emit("get_main_info", { tgid: window.Telegram.WebApp.initDataUnsafe.user.id, name: window.Telegram.WebApp.initDataUnsafe.user.first_name})
    socket.emit("get_tape", { tgid: window.Telegram.WebApp.initDataUnsafe.user.id})
    window.Telegram.WebApp.BackButton.hide()
    
  }, [])

  useEffect(() => {
    if (userWallet !== "") {
      socket.emit("set_wallet", { tgid: window.Telegram.WebApp.initDataUnsafe.user.id, wallet: userWallet})
      setWallet(userWallet)
    }
  }, [userWallet])


  socket.on("set_tape", ({ img, places, last_name }) => {
    setTapeImg(img)
    setTapePlaces(places)
    setTapeLastName(last_name)
  })
  

  socket.on("send_main_info", info => {
    let tscore = info.score.toString()
    let tspins = formatSI(info.spins.toString())
    let wallet = info.wallet.toString()

    while (tscore.length < 6) {
      tscore = "0" + tscore
    }

    while (tspins.length < 3) {
      tspins = "0" + tspins
    }
    setAdLink(info.link)
    setScore(tscore)
    setSpins(tspins)
    setWallet(wallet)
    setHasTasks(info.has_tasks)
  })

  const roll = (reel, offset = 0, color, count, nft, nft_count) => {
    

    const cur_color = curSpinPosition[offset]

    const style = getComputedStyle(reel),
    backgroundPositionY = parseFloat(style["background-position-y"]),
    delta = 41
    
    let spin_distance = Math.abs(tapePlaces[cur_color] - tapePlaces[color])

    if (tapePlaces[cur_color] > tapePlaces[color]) {
      spin_distance = Math.abs(tapePlaces['green'] - tapePlaces[color]) + Math.abs(tapePlaces['orange'] - tapePlaces[cur_color]) + 1
    }

    setTimeout(() => {
      reel.style.backgroundPositionY = `${backgroundPositionY + (delta * spin_distance + delta*nft_count*4)}px`//1229.7
      reel.style.transition = `background-position-y ${(8 + 1 * delta) * time_per_icon}ms cubic-bezier(.41,-0.01,.63,1.09)`;
    }, offset * time_per_icon);

    setTimeout(() => {
      if (offset > 3) {
        console.log(count)
        let tmp_score = 0
            switch (parseInt(count)) {
                case 0:
                  tmp_score = 10
                    break;
                case 2:
                  tmp_score = 20
                    break;
                case 3:
                  tmp_score = 30
                    break;
                case 4:
                  tmp_score = 50
                    break;
                case 5:
                  tmp_score = 100
                  setShowNft(true)
                    break;
                    
            }
        setCanRoll(true)
        
        let tscore = parseInt(score)
        if (tscore > 0) {
          tscore = (tscore + tmp_score).toString()
          while (tscore.length < 6) {
            tscore = "0" + tscore
          }
          setScore(tscore)
          
        }
        if (nft) {
          socket.emit("add_nft_order", { tgid: window.Telegram.WebApp.initDataUnsafe.user.id, username: window.Telegram.WebApp.initDataUnsafe.user.username,  nft: nft})
        }

        let tspins = formatSI(parseInt(spins))

        if ((tspins - 1) < 0) {
          setCanRoll(false)
        }

        if ((autoSpin) && (spins > 0)) {
          console.log(nft)
          if (nft) {

          } else {
            socket.emit("spin", { tgid: window.Telegram.WebApp.initDataUnsafe.user.id })
            if (tspins > 0) {
              tspins = (tspins - 1).toString()
              while (tspins.length < 3) {
                tspins = "0" + tspins
              } 
              setSpins(tspins)
              
            }

            setCanRoll(false)
          }
        }

      }
    }, (8 + 1 * delta) * time_per_icon + offset * 150)

    

    /*const delta = (offset + 2) * num_icons + Math.round(Math.random() * num_icons); 
    
    const style = getComputedStyle(reel),
    
    tmp = 54,

    backgroundPositionY = parseFloat(style["background-position-y"]),
    targetBackgroundPositionY = backgroundPositionY + delta * tmp,
    normTargetBackgroundPositionY = targetBackgroundPositionY%(num_icons * tmp);

    setTimeout(() => { 
    reel.style.transition = `background-position-y ${(8 + 1 * delta) * time_per_icon}ms cubic-bezier(.41,-0.01,.63,1.09)`;
    reel.style.backgroundPositionY = `${backgroundPositionY + delta * tmp}px`;
    }, offset * 150);

    setTimeout(() => {
			reel.style.transition = `none`;
			reel.style.backgroundPositionY = `${normTargetBackgroundPositionY}px`;
			
		}, (8 + 1 * delta) * time_per_icon + offset * 150);*/
		

  }

  const rollAll = (colors, count, nft, nft_count) => {
    const reelsList = document.querySelectorAll('.roulette_tape_wrap > .roulette_tape');
    Object.values(reelsList).map((reel, i) => {
      roll(reel, i, colors[i], count, nft, nft_count)
    })
    setNftUrl(nft)
    setCurSpinPosition(colors)
    
  }

  const onSpinRoll = () => {
    if (userWallet !== "") {
      if (canRoll) {
        window.Telegram.WebApp.HapticFeedback.impactOccurred("soft")
        if (autoSpin) {
          socket.emit("spin_all", { tgid: window.Telegram.WebApp.initDataUnsafe.user.id, spins: spins })
          setSpins("000")
          

        } else {
          if (spins > 0) {
            socket.emit("spin", { tgid: window.Telegram.WebApp.initDataUnsafe.user.id })
            let tspins = formatSI(parseInt(spins))
            if (tspins > 0) {
              tspins = (tspins - 1).toString()
              while (tspins.length < 3) {
                tspins = "0" + tspins
              } 
              setSpins(tspins)
              
            }

            setCanRoll(false)
          }
        }
      }
    } else {
    toast("Please, connect your wallet first")
  }
}

  const onWalletDisconnect = () => {
    _.disconnect()
    setWallet("")
    socket.emit("set_wallet", { tgid: window.Telegram.WebApp.initDataUnsafe.user.id, wallet: '' })
  }

  socket.off("spin_res").on("spin_res", ({res, count, nft, nft_count}) => {
    rollAll(res, count, nft, nft_count)
    
  })

  socket.off("spin_all_res").on("spin_all_res", ({score_sum, nfts}) => {
    let add_arr = []
    let tscore = parseInt(score) + score_sum
    if (tscore > 0) {
      tscore = tscore.toString()
      while (tscore.length < 6) {
        tscore = "0" + tscore
      } 
      setScore(tscore)
      
    }
    add_arr.push({link: `${config.BACKEND_URL}/nfts/coins/coins.png`, count: score_sum})
    nfts.forEach(v => {
      add_arr.push({link: v, count: 1})
    })
    setAutoSpinAwards([
      ...autoSpinAwards,
      ...add_arr
    ])
    setAutoSpinShow(true)
  })

  socket.on("update", ({}) => {
    window.location.reload()
  })

  return (
    <>
      <div id='main_background'>
        <div id='main_mug'>
          <div className='main_rivets left'>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
          </div>

          <div id='main_content'>
            <div id='main_up'>
              <div onClick={() => navigate("/rating")} className='main_up_button'>
                <div className='main_up_button_s'>
                  <div className='main_up_button_f'>
                    <div id='main_up_counter_num'>
                      {
                        Object.values(score).map(v => {
                          return (
                            <>
                              <span>{v}</span>
                              
                            </>
                          )
                        })
                          
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className='main_rivets up'>
                <div className='main_rivet'></div>
                <div className='main_rivet'></div>
              </div>
              <div ref={walletPanelRef} id='main_wallet_container'>
                <div onClick={() => {(userWallet === "") ? _.openModal() : setShowWalletPanel(!showWalletPanel)}} className='main_up_button'>
                  <div className='main_up_button_s'>
                    <div className='main_up_button_f wallet'>
                      <div className='main_up_button_t'>
                        <div className='main_up_button_a'>
                          { (wallet === '') && <p>Wallet</p> }
                          { (wallet !== '') && <p>{`${wallet.substring(0, 3)}...${wallet.substring(wallet.length - 3)}`}</p> }
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                {((wallet !== '') && (showWalletPanel)) &&<div id='main_wallet_panel'>
                      <p onClick={onWalletDisconnect}>Disconnect</p>
                </div>}
              </div>
            </div>
            <div id='main_center'>
              <div id='main_roulette'>
                <div id='main_roulette_tint'>
                  <img id='main_logo' src={logo} alt='logo'/>
                  <div id='main_machine_wrap'>
                    <div id='main_machine'>
                        <div id='main_adblock'>
                          <div id='main_machine_left_rivets'>
                            <div className='main_rivet'></div>
                            <div className='main_rivet'></div>
                          </div>
                          <div onClick={() => (adLink !== '') && window.Telegram.WebApp.openLink(adLink)} id='main_machine_center'>
                            <div id='main_machine_center_rivets'>
                              <div className='main_rivet'></div>
                              <div className='main_rivet'></div>
                            </div>
                            <div id='main_machine_center_content_wrap'>
                              <div id='main_machine_center_content'>
                                {(adLink !== '') && <img src={`${config.BACKEND_URL}/nfts/ad/banner.png`} alt='ad'/>}
                              </div>
                            </div>
                          </div>
                          <div id='main_machine_right_rivets'>
                            <div className='main_rivet'></div>
                            <div className='main_rivet'></div>
                          </div>
                        </div>
                        <div id='main_machine_roulette'>
                          <div id='main_machine_roulette_left_rivets'>
                            <div className='main_rivet'></div>
                            <div className='main_rivet'></div>
                            <div className='main_rivet'></div>
                            <div className='main_rivet'></div>
                          </div>
                          <div id='main_machine_roulette_center'>
                            <div className='main_machine_roulette_center_rivets up'>
                              <div className='main_rivet'></div>
                              <div className='main_rivet'></div>
                              <div className='main_rivet'></div>
                            </div>
                            <div id='main_machine_roulette_center_wrap'>
                              <div id='main_machine_roulette_center_wrap_s'>
                                <div id='main_machine_roulette_center_wrap_t'>
                                  <div id='main_machine_roulette_center_wrap_f'>
                                    <div id='main_machine_roulette_center_wrap_fifth'>
                                      <div id='main_machine_roulette_center_wrap_six'>
                                        <div id='main_machine_roulette_center_wrap_seven'>
                                          <div id='main_machine_roulette_center_wrap_content'>
                                            <div className='roulette_tape_wrap'>
                                              <div style={{ backgroundImage: `url( ${tapeImg} )` }} className='roulette_tape'>
                                          
                                              </div>
                                            </div>
                                            <div className='roulette_tape_wrap'>
                                              <div  style={{ backgroundImage: `url( ${tapeImg} )` }} className='roulette_tape'>
                                          
                                              </div>
                                            </div>
                                            <div className='roulette_tape_wrap'>
                                              <div  style={{ backgroundImage: `url( ${tapeImg} )` }} className='roulette_tape'>
                                          
                                              </div>
                                            </div>
                                            <div className='roulette_tape_wrap'>
                                              <div  style={{ backgroundImage: `url( ${tapeImg} )` }} className='roulette_tape'>
                                          
                                              </div>
                                            </div>
                                            <div className='roulette_tape_wrap'>
                                              <div  style={{ backgroundImage: `url( ${tapeImg} )` }} className='roulette_tape'>
                                          
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>  
                                    </div>  
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='main_machine_roulette_center_rivets down'>
                              <div className='main_rivet'></div>
                              <div className='main_rivet'></div>
                              <div className='main_rivet'></div>
                            </div>
                            <div id='main_spins'>
                              <div id='main_spins_left_rivets'>
                                <div className='main_rivet mini'></div>
                              </div>
                              <div id='main_spins_background'>
                                <div id='main_spins_background_s'>
                                  <div id='main_spins_background_t'>
                                    <div id='main_spins_counter'>
                                      {
                                        Object.values(spins).map(v => {
                                          return <span>{v}</span>
                                        })
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div id='main_spins_right_rivets'>
                                <div className='main_rivet mini'></div>
                              </div>
                            </div>
                          </div>
                          <div id='main_machine_roulette_right_rivets'>
                            <div className='main_rivet'></div>
                            <div className='main_rivet'></div>
                            <div className='main_rivet'></div>
                            <div className='main_rivet'></div>
                          </div>
                        </div>
                    </div>
                  </div>
                  <SpinButton disabled={canRoll} autospin={autoSpin} setAutospin={setAutoSpin} onClick = {onSpinRoll}/>
                </div>
              </div>
            </div>
            <div id='main_down'>
              <div id='main_down_down'>
                <SimpButton hasTasks={hasTasks} text="BOOST" onClick={() => navigate("/boost")} />
                <SimpButton text="INFO" onClick={() => navigate("/info")} />
              </div>
            </div>
          </div>

          <div className='main_rivets right'>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
            <div className='main_rivet'></div>
          </div>
        </div>
      </div>
      { (showNft) && <Shield name="CONGRATS" show={showNft} onClick={() => {setShowNft(false); (autoSpin) && onSpinRoll()}} onClose={() => setShowNft(false)} text="OK" src={nftUrl} />}
        { (autoSpinShow) && <AutoShield name="CONGRATS" awards={autoSpinAwards} show={autoSpinShow} onClick={() => setAutoSpinShow(false)} onClose={() => setAutoSpinShow(false)} text="OK" /> }
    </>
  )
}

export default Main