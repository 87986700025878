import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './NFT.css'
import { Body } from '../../components/Body/Body'
import { useTranslation } from '@plaidev/react-i18n';

const NFT = () => {

  const navigate = useNavigate()
  
  useEffect(() => {
    window.Telegram.WebApp.BackButton.show()
    window.Telegram.WebApp.onEvent('backButtonClicked', () => navigate("/info"))
    return () => window.Telegram.WebApp.offEvent('backButtonClicked', () => navigate("/info"))
  }, [])

  const data = [
    useTranslation({
      ru: {
        message: 'Артефакт - 5 шт. (не входит в раздачу игрового автомата)',
      },
      en: {
        message: 'Artifact - 5 pcs (Not included in the slot machine distribution)',
      },
      zh: {
          message: '工艺品 - 5 件（不包括在老虎机分配中）'
      },
      tr: {
          message: "Artifact - 5 adet (Slot makinesi dağıtımına dahil değildir)"
      },
      ar: {
          message: 'قطعة أثرية - 5 قطع (غير مشمولة في توزيع ماكينات القمار)'
      },
      es: {
          message: 'Artefacto - 5 piezas (No incluido en la distribución de la máquina tragaperras)'
      }
    }),
    useTranslation({
      ru: {
        message: 'Легендарный - 100 шт - шанс получения 0.003%',
      },
      en: {
        message: 'Legendary - 100 pcs - chance of receiving 0.003%',
      },
      zh: {
          message: '传奇 - 100 件 - 获得几率 0.003'
      },
      tr: {
          message: "Efsanevi - 100 adet - alma şansı %0.003"
      },
      ar: {
          message: 'أسطوري - 100 قطعة - فرصة الحصول على %0.003'
      },
      es: {
          message: 'Legendario - 100 unidades - probabilidad de recibir 0.003%.'
      }
    }),
    useTranslation({
      ru: {
        message: 'Эпический - 500 шт - шанс получения 0.005%',
      },
      en: {
        message: 'Epic - 500 pcs - chance of receiving 0.005%',
      },
      zh: {
          message: '史诗级 - 500 件 - 获得几率为 0.005'
      },
      tr: {
          message: "Destansı - 500 adet - alma şansı %0.005"
      },
      ar: {
          message: '%ملحمة - 500 قطعة - فرصة الاستلام 0.005'
      },
      es: {
          message: 'Épico - 500 unidades - probabilidad de recibir 0.005%.'
      }
    }),
    useTranslation({
      ru: {
        message: 'Редкие - 1,000 шт - шанс получения 0.005%',
      },
      en: {
        message: 'Rare - 1,000 pcs - chance of receiving 0.005%',
      },
      zh: {
          message: '稀有 - 1,000 件 - 获得几率为 0.005'
      },
      tr: {
          message: "Nadir - 1.000 adet - alma şansı %0.005"
      },
      ar: {
          message: 'نادرة - 1,000 قطعة - فرصة الحصول على %0.005'
      },
      es: {
          message: 'Raro - 1.000 unidades - probabilidad de recibir 0.005%.'
      }
    }),
    useTranslation({
      ru: {
        message: 'Необычный - 2 500 штук - шанс получения 0.005%',
      },
      en: {
        message: 'Uncommon - 2,500 pcs - chance of receiving 0.005%',
      },
      zh: {
          message: '不常见 - 2 500 件 - 获得几率为 0.005%'
      },
      tr: {
          message: "Yaygın olmayan - 2.500 adet - alma şansı 0.005%"
      },
      ar: {
          message: 'غير مألوف - 2,500 قطعة - فرصة الحصول على %0.005'
      },
      es: {
          message: 'Poco común - 2.500 unidades - probabilidad de recibir 0.005%.'
      }
    }),
    useTranslation({
      ru: {
        message: 'Обычный - 6 000 шт - шанс получения 0.005%',
      },
      en: {
        message: 'Common - 6,000 pcs - chance of receiving 0.005%',
      },
      zh: {
          message: '普通 - 6 000 件 - 收货几率为 0.005%'
      },
      tr: {
          message: "Yaygın - 6.000 adet - alma şansı 0.005%"
      },
      ar: {
          message: 'شائع - 6,000 قطعة - فرصة الاستلام %0.005'
      },
      es: {
          message: 'Común - 6.000 unidades - probabilidad de recibir 0.005%.'
      }
    }),
  ]

  const ptext = [
    useTranslation({
      ru: {
        message: 'Первая коллекция внутриигровых NFT состоит из 10 105 предметов, 25 типов и 6 редкостей.',
      },
      en: {
        message: 'The first collection of in-game NFTs consists of 10,105 items, 25 types, and 6 rarities.',
      },
      zh: {
          message: '第一批游戏内 NFT 包含 10,105 件物品、25 种类型和 6 种稀有物品。'
      },
      tr: {
          message: "Oyun içi NFT'lerin ilk koleksiyonu 10.105 eşya, 25 tür ve 6 nadirlikten oluşmaktadır."
      },
      ar: {
          message: 'تتكون المجموعة الأولى من عناصر NFT داخل اللعبة من 10,105 عنصرًا و25 نوعًا و6 عناصر نادرة.'
      },
      es: {
          message: 'La primera colección de NFT del juego consta de 10.105 objetos, 25 tipos y 6 rarezas.'
      }
    }),
    useTranslation({
      ru: {
        message: 'NFT призваны повысить внутриигровые статы владельца и заработать дополнительные джеттоны (10% от общего объема эмиссии будет распределено между владельцами NFT) и бонус, информация о котором будет раскрыта в свое время.',
      },
      en: {
        message: "NFTs are designed to enhance the owner's in-game stats and to earn additional Jettons (10% of the total issuance will be distributed among NFT holders) and a bonus, information about which will be revealed in its time.",
      },
      zh: {
          message: 'NFT 旨在提高所有者在游戏中的数据，并获得额外的杰顿（总发行量的 10%将分配给 NFT 持有者）和奖金，相关信息将适时公布。'
      },
      tr: {
          message: "NFT'ler, sahibinin oyun içi istatistiklerini geliştirmek ve ek Jetonlar (toplam ihracın %10'u NFT sahipleri arasında dağıtılacaktır) ve hakkında bilgi zamanında açıklanacak bir bonus kazanmak için tasarlanmıştır."
      },
      ar: {
          message: 'تم تصميم NFTs لتعزيز إحصائيات المالك داخل اللعبة وكسب جيتونات إضافية (سيتم توزيع 10٪ من إجمالي الإصدار على حاملي NFT) ومكافأة سيتم الكشف عن معلومات عنها في وقتها.'
      },
      es: {
          message: 'Los NFT están diseñados para mejorar las estadísticas en el juego del propietario y para ganar Jettons adicionales (el 10% de la emisión total se distribuirá entre los poseedores de NFT) y una bonificación, cuya información se revelará en su momento.'
      }
    }),
    useTranslation({
      ru: {
        message: '50% NFT встроены в игровой автомат mini DApp.',
      },
      en: {
        message: '50% of the NFTs are embedded in the mini DApp slot machine.',
      },
      zh: {
          message: '50%的 NFT 被嵌入到小型 DApp 老虎机中。'
      },
      tr: {
          message: "NFT'lerin 50%'si mini DApp slot makinesine yerleştirilmiştir."
      },
      ar: {
          message: 'يتم تضمين 50% من NFTs في ماكينة سلوت DApp الصغيرة.'
      },
      es: {
          message: 'El 50% de los NFT están incrustados en la tragaperras mini DApp.'
      }
    }),
    useTranslation({
      ru: {
        message: '50% NFT будут проданы членам общины, а вырученные средства пойдут на развитие проекта.',
      },
      en: {
        message: '50% of the NFTs will be sold to community members, with the proceeds going towards the development of the project.',
      },
      zh: {
          message: '50% 的 NFT 将出售给社区成员，所得收入将用于项目开发。'
      },
      tr: {
          message: "NFT'lerin %50'si topluluk üyelerine satılacak ve elde edilen gelir projenin geliştirilmesine aktarılacaktır."
      },
      ar: {
          message: 'سيتم بيع 50٪ من NFTs لأفراد المجتمع المحلي، على أن تذهب العائدات إلى تطوير المشروع.'
      },
      es: {
          message: 'El 50% de los NFT se venderán a miembros de la comunidad, y los beneficios se destinarán al desarrollo del proyecto.'
      }
    }),
  ]

  const he2 = useTranslation({
    ru: {
      message: 'Редкость и шансы получения',
    },
    en: {
      message: 'Rarities and the chance of receiving',
    },
    zh: {
        message: '稀有性和获得的机会'
    },
    tr: {
        message: "Nadirlikler ve alma şansı"
    },
    ar: {
        message: 'النوادر وفرصة الحصول على'
    },
    es: {
        message: 'Rarezas y la posibilidad de recibir'
    }
  })

  return (
    <Body>
      <div id='nft_main'>
        <p>NFT</p>
        <div className='nft_description' >
        {ptext.map(v => {
            return (
              <p>{v.message}</p>
            )
          })}  
        </div>

        <p style={{fontSize: "16px", fontWeight: "bolder", color: "white"}}>{he2.message}</p>
        <div className='nft_description' >
          {data.map(v => {
              return (
                <p>{v.message}</p>
              )
            })}
        </div>
        
      </div>
    </Body>
  )
}

export default NFT