import React from 'react'
import { useNavigate } from 'react-router-dom'
import logo from './img/DoomCity.png'
import "./Body.css"
import Button from '../Button/Button'

export const Body = (props) => {

    const navigate = useNavigate()

  return (
    <div id='body_main'>
        <div style={{paddingBottom: "10px"}} className='main_rivets left'>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
        </div>

        <div id='body_center'>
            <div className='main_rivets up body'>
              <div className='main_rivet'></div>
              <div className='main_rivet'></div>
            </div>
            <div id='body_black'>
                <div id='body_logo'>
                    <img src={logo} alt='logo'/>
                </div>
                <div id='body_content'>
                    {props.children}
                </div>
                <div id='body_down'>
                    <div className='body_down_void'>
                        <div className='body_up_corner left'>
                            <div className='body_up_corner up'>
                                
                            </div>
                            <div className='body_up_corner down'>
                                    
                            </div>
                        </div>
                        <div className='body_up_hor'>
                            <div className='body_up_hor up'>
                                
                            </div>
                            <div className='body_up_hor down'>
                                
                            </div>
                        </div>
                    </div>
                    <div id='body_down_center'>
                        <Button onClick={() => navigate("/")} text="EXIT" />
                        <div id='body_down_line'>
                            <div id='body_down_line_line'></div>
                        </div>
                    </div>
                    <div className='body_down_void'>
                        <div className='body_up_hor'>
                            <div className='body_up_hor up'>
                                    
                            </div>
                            <div className='body_up_hor down'>
                                        
                            </div>
                        </div>
                        <div className='body_up_corner right'>
                            <div className='body_up_corner up'>
                                
                            </div>
                            <div className='body_up_corner down'>
                                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style={{paddingBottom: "10px"}} className='main_rivets right'>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
          <div className='main_rivet'></div>
        </div>
    </div>
  )
}
