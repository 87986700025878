import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import "./Roadmap.css"
import { Body } from '../../components/Body/Body'
import { useTranslation } from '@plaidev/react-i18n';

const Roadmap = () => {

  const navigate = useNavigate()
  
  useEffect(() => {
    window.Telegram.WebApp.BackButton.show()
    window.Telegram.WebApp.onEvent('backButtonClicked', () => navigate("/info"))
    return () => window.Telegram.WebApp.offEvent('backButtonClicked', () => navigate("/info"))
  }, [])

  const transes = [
    useTranslation({
      ru: {
        message: 'Выпуск первой коллекции NFT - 3 квартал 2024 г.',
      },
      en: {
        message: 'Release of the first NFT collection - 3Q 2024',
      },
      zh: {
          message: '发布首个 NFT 系列 - 2024 年第 3 季度'
      },
      tr: {
          message: 'İlk NFT koleksiyonunun piyasaya sürülmesi - 3Ç 2024'
      },
      ar: {
          message: 'إصدار أول مجموعة NFT - الربع الثالث من عام 2024'
      },
      es: {
          message: 'Lanzamiento de la primera colección NFT - 3T 2024'
      }
    }),
    useTranslation({
      ru: {
        message: 'Запуск мини DApp - 3 квартал 2024 года',
      },
      en: {
        message: 'Launch of mini DApp - 3Q 2024',
      },
      zh: {
          message: '推出迷你 DApp - 2024 年第 3 季度'
      },
      tr: {
          message: "Mini DApp'ın lansmanı - 3Ç 2024"
      },
      ar: {
          message: 'إطلاق تطبيق DApp المصغر - الربع الثالث من عام 2024'
      },
      es: {
          message: 'Lanzamiento de la mini DApp - 3T 2024'
      }
    }),
    useTranslation({
      ru: {
        message: 'Кое-что о НФТ - 3 квартал 2024 года',
      },
      en: {
        message: 'Something about NFTs - 3Q 2024',
      },
      zh: {
          message: '有关 NFT 的事情 - 2024 年第 3 季度'
      },
      tr: {
          message: "NFT'ler hakkında bir şey - 3Ç 2024"
      },
      ar: {
          message: 'شيء عن NFTs - الربع الثالث من عام 2024'
      },
      es: {
          message: 'Algo sobre las NFT - 3T 2024'
      }
    }),
    useTranslation({
      ru: {
        message: 'Продажа 50% НФТ - 3 квартал 2024 года',
      },
      en: {
        message: 'Sale of 50% NFTs - 3Q 2024',
      },
      zh: {
          message: '出售 50%的短期融资券 - 2024 年第 3 季度'
      },
      tr: {
          message: "50 NFT'lerin satışı - 3Ç 2024"
      },
      ar: {
          message: 'بيع 50٪ من عقود التمويل غير النقدي - الربع الثالث من عام 2024'
      },
      es: {
          message: 'Venta de NFT al 50% - 3T 2024'
      }
    }),
    useTranslation({
      ru: {
        message: 'Первая фаза нового проекта - 3 квартал 2024 года',
      },
      en: {
        message: 'First phase of the new project - 3Q 2024',
      },
      zh: {
          message: '新项目第一阶段--2024 年第 3 季度'
      },
      tr: {
          message: 'Yeni projenin ilk aşaması - 3Ç 2024'
      },
      ar: {
          message: 'المرحلة الأولى من المشروع الجديد - الربع الثالث من عام 2024'
      },
      es: {
          message: 'Primera fase del nuevo proyecto - 3T 2024'
      }
    }),
    useTranslation({
      ru: {
        message: 'Ретродроп Jetton - 4 квартал 2024 года',
      },
      en: {
        message: 'Retrodrop Jetton - 4Q 2024',
      },
      zh: {
          message: '杰顿河退流--2024 年第 4 季度'
      },
      tr: {
          message: 'Retrodrop Jetton - 4Ç 2024'
      },
      ar: {
          message: 'ريترودروب جيتون - الربع الرابع من عام 2024'
      },
      es: {
          message: 'Retrodrop Jetton - 4Q 2024'
      }
    }),
    useTranslation({
      ru: {
        message: 'Листинг - 4 квартал 2024 г.',
      },
      en: {
        message: 'Listing - 4Q 2024',
      },
      zh: {
          message: '上市 - 2024 年第 4 季度'
      },
      tr: {
          message: 'Listeleme - 4Ç 2024'
      },
      ar: {
          message: 'الإدراج - الربع الرابع 2024'
      },
      es: {
          message: 'Listado - 4T 2024'
      }
    }),
    useTranslation({
      ru: {
        message: 'Вторая фаза нового проекта - 4 квартал 2024 года',
      },
      en: {
        message: 'Second phase of the new project - 4Q 2024',
      },
      zh: {
          message: '新项目第二阶段--2024 年第 4 季度'
      },
      tr: {
          message: 'Yeni projenin ikinci aşaması - 4Ç 2024'
      },
      ar: {
          message: 'المرحلة الثانية من المشروع الجديد - الربع الرابع من عام 2024'
      },
      es: {
          message: 'Segunda fase del nuevo proyecto - 4T 2024'
      }
    }),
    useTranslation({
      ru: {
        message: 'Релиз игры Doom City - 1 квартал 2025 года',
      },
      en: {
        message: 'Release of Doom City game - 1Q 2025',
      },
      zh: {
          message: '发布末日之城游戏 - 2025 年第 1 季度'
      },
      tr: {
          message: 'Doom City oyununun piyasaya sürülmesi - 1Ç 2025'
      },
      ar: {
          message: 'إصدار لعبة Doom City - الربع الأول من عام 2025'
      },
      es: {
          message: 'Lanzamiento del juego Doom City - 1T 2025'
      }
    }),
    useTranslation({
      ru: {
        message: 'Третья фаза нового проекта - 1 квартал 2025 года',
      },
      en: {
        message: 'Third phase of the new project - 1Q 2025',
      },
      zh: {
          message: '新项目第三阶段 - 2025 年第 1 季度'
      },
      tr: {
          message: 'Yeni projenin üçüncü aşaması - 1Ç 2025'
      },
      ar: {
          message: 'المرحلة الثالثة من المشروع الجديد - الربع الأول من عام 2025'
      },
      es: {
          message: 'Tercera fase del nuevo proyecto - 1T 2025'
      }
    }),
    useTranslation({
      ru: {
        message: 'Премьера мультсериала Doom City - 2-3 квартал 2025 года',
      },
      en: {
        message: 'Premiere of Doom City animated series - 2-3Q 2025',
      },
      zh: {
          message: '末日之城》动画系列首映 - 2025 年 2-3 季度'
      },
      tr: {
          message: 'Doom City animasyon dizisinin prömiyeri - 2-3Ç 2025'
      },
      ar: {
          message: 'العرض الأول لمسلسل الرسوم المتحركة "مدينة الموت" - 2-3Q 2025'
      },
      es: {
          message: 'Estreno de la serie de animación Doom City - 2-3Q 2025'
      }
    }),

  ]

  //done active wait
const data = [
  { name: transes[0].message, status: "active" },
  { name: transes[1].message, status: "wait" },
  { name: transes[2].message, status: "wait" },
  { name: transes[3].message, status: "wait" },
  { name: transes[4].message, status: "wait" },
  { name: transes[5].message, status: "wait" },
  { name: transes[6].message, status: "wait" },
  { name: transes[7].message, status: "wait" },
  { name: transes[8].message, status: "wait" },
  { name: transes[9].message, status: "wait" },
  { name: transes[10].message, status: "wait" }
]
  
  return (
    <Body>
      <div id='roadmap_main'>
        <p id='roadmap_header'>ДОРОЖНАЯ КАРТА</p>
        <div id='roadmap_content'>
          {
            Object.values(data).map(v => {
              return (
                <div className={`roadmap_item ${v.status}`}>
                  <p>{v.name}</p>
                </div>
              )
            })
          }
        </div>
      </div>
    </Body>
  )
}

export default Roadmap