import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Body } from '../../components/Body/Body'
import "./AboutGame.css"
import { useTranslation } from '@plaidev/react-i18n';

const AboutGame = () => {

  const navigate = useNavigate()
  
  useEffect(() => {
    window.Telegram.WebApp.BackButton.show()
    window.Telegram.WebApp.onEvent('backButtonClicked', () => navigate("/info"))
    return () => window.Telegram.WebApp.offEvent('backButtonClicked', () => navigate("/info"))
  }, [])

  const content = [
    useTranslation({
      ru: {
        message: 'Дисклеймер: этот мини DApp не является игрой DOOM CITY и создан для привлечения и дальнейшего поощрения сообщества проекта.',
      },
      en: {
        message: 'Disclaimer: this mini DApp is not the DOOM CITY game and it is created to attract and further reward the project community.',
      },
      zh: {
          message: '免责声明：本迷你 DApp 并非《毁灭都市》游戏，其创建目的是为了吸引和进一步奖励项目社区。'
      },
      tr: {
          message: 'Yasal Uyarı: Bu mini DApp, DOOM CITY oyunu değildir ve proje topluluğunu çekmek ve daha fazla ödüllendirmek için oluşturulmuştur.'
      },
      ar: {
          message: 'تنويه: هذا التطبيق المصغّر ليس لعبة DOOM CITY وقد تم إنشاؤه لجذب مجتمع المشروع ومكافأته بشكل أكبر.'
      },
      es: {
          message: 'Descargo de responsabilidad: esta mini DApp no es el juego DOOM CITY y se crea para atraer y recompensar aún más a la comunidad del proyecto.'
      }
    }),
    useTranslation({
      ru: {
        message: 'Doom City - это реинкарнация культовой веб-игры "Город без надежды", основанная на блокчейне TON. Игра погружает вас в захватывающий постапокалиптический мир, где после разрушительной ядерной войны выжившие поселились в последнем городе человечества.',
      },
      en: {
        message: 'Doom City is the reincarnation of the iconic web game "The City Without Hope" based on the TON blockchain. The game immerses you in an exciting post-apocalyptic world where after a devastating nuclear war survivors have settled in the last city of humanity.',
      },
      zh: {
          message: '末日之城》是基于 TON 区块链的标志性网页游戏《没有希望的城市》的翻版。游戏让您沉浸在一个令人兴奋的末世世界，在一场毁灭性的核战争之后，幸存者们在人类最后的城市定居下来。'
      },
      tr: {
          message: 'Doom City, TON blok zincirine dayanan ikonik web oyunu "The City Without Hope "un reenkarnasyonudur. Oyun sizi, yıkıcı bir nükleer savaştan sonra hayatta kalanların insanlığın son şehrine yerleştiği heyecan verici bir kıyamet sonrası dünyaya götürüyor.'
      },
      ar: {
          message: 'Doom City هي إعادة تجسيد للعبة الويب الشهيرة "مدينة بلا أمل" القائمة على سلسلة الكتل TON. تغمرك اللعبة في عالم ما بعد المروع المثير حيث استقر الناجون بعد حرب نووية مدمرة في آخر مدينة للبشرية.'
      },
      es: {
          message: 'Doom City es la reencarnación del icónico juego web "La ciudad sin esperanza" basado en la blockchain TON. El juego te sumerge en un apasionante mundo postapocalíptico en el que, tras una devastadora guerra nuclear, los supervivientes se han asentado en la última ciudad de la humanidad.'
      }
    }),
    useTranslation({
      ru: {
        message: 'В этом новом темном мире пять великих семей завязали неустанную войну за господство, контроль территорий и ресурсы. Каждая семья обладает своими уникальными характеристиками и преимуществами, создавая широкие возможности для стратегического планирования.',
      },
      en: {
        message: 'In this new dark world, five great families are locked in a relentless war for domination, territory control, and resources. Each family has its unique characteristics and advantages, creating extensive opportunities for strategic planning.',
      },
      zh: {
          message: '在这个全新的黑暗世界中，五大家族为争夺统治权、领土控制权和资源展开了无情的战争。每个家族都有自己独特的特点和优势，为战略规划创造了大量机会。'
      },
      tr: {
          message: 'Bu yeni karanlık dünyada, beş büyük aile hakimiyet, bölge kontrolü ve kaynaklar için amansız bir savaşa kilitlenmiş durumda. Her ailenin kendine has özellikleri ve avantajları var ve bu da stratejik planlama için geniş fırsatlar yaratıyor.'
      },
      ar: {
          message: 'في هذا العالم المظلم الجديد، تخوض خمس عائلات كبرى حربًا لا هوادة فيها من أجل الهيمنة والسيطرة على الأراضي والموارد. لكل عائلة خصائصها ومزاياها الفريدة، مما يخلق فرصًا واسعة للتخطيط الاستراتيجي.'
      },
      es: {
          message: 'En este nuevo mundo oscuro, cinco grandes familias están enzarzadas en una guerra sin cuartel por la dominación, el control del territorio y los recursos. Cada familia tiene sus características y ventajas únicas, lo que crea amplias oportunidades para la planificación estratégica.'
      }
    }),
    useTranslation({
      ru: {
        message: 'Присоединившись к одной из семей, игроки могут создавать банды, чтобы захватывать новые районы города. Каждая завоеванная территория приносит стратегические и экономические выгоды, укрепляя влияние вашей банды.',
      },
      en: {
        message: "By joining one of the families, players can form gangs to capture new areas of the city. Each conquered territory brings strategic and economic benefits, strengthening your gang's influence.",
      },
      zh: {
          message: '加入某个家族后，玩家可以组建帮派，占领城市的新区域。每攻占一块领地都会带来战略和经济利益，增强帮派的影响力。'
      },
      tr: {
          message: 'Oyuncular ailelerden birine katılarak şehrin yeni bölgelerini ele geçirmek için çeteler kurabilirler. Fethedilen her bölge stratejik ve ekonomik faydalar sağlayarak çetenizin etkisini güçlendirir.'
      },
      ar: {
          message: 'من خلال الانضمام إلى إحدى العائلات، يمكن للاعبين تشكيل عصابات للاستيلاء على مناطق جديدة من المدينة. تجلب كل منطقة يتم احتلالها فوائد استراتيجية واقتصادية، مما يعزز نفوذ عصابتك.'
      },
      es: {
          message: 'Al unirse a una de las familias, los jugadores pueden formar bandas para conquistar nuevas zonas de la ciudad. Cada territorio conquistado aporta beneficios estratégicos y económicos, reforzando la influencia de tu banda.'
      }
    }),
    useTranslation({
      ru: {
        message: 'Чтобы стать могущественнее и богаче, игрокам нужно сражаться в городе и за его пределами с NPC-монстрами и ушедшими в мир иной AI-киборгами, повышая уровень и добывая ценные трофеи: от оружия и брони до различных ресурсов, которые можно использовать в различных ремеслах. Овладение этими ремеслами может изменить баланс сил в вашу пользу. Стать мастером хотя бы одного ремесла - задача не из легких, но те, кому это удастся, будут вечно обеспечены.',
      },
      en: {
        message: 'To become more powerful and wealthy, players need to fight within the city and beyond its borders with NPC monsters and gone off  AI cyborgs, thereby leveling up and obtaining valuable trophies: from weapons and armor to various resources that can be used in different crafts. Mastering these crafts can shift the balance of power in your favor. Becoming a master of at least one craft is no easy task, but those who succeed will be forever well-provided for.',
      },
      zh: {
          message: '为了变得更加强大和富有，玩家需要在城内和城外与 NPC 怪物和被淘汰的 AI 机械人作战，从而提升等级并获得宝贵的战利品：从武器和盔甲到可用于不同工艺的各种资源。掌握这些工艺可以使力量平衡向对自己有利的方向转变。要成为至少一种工艺的大师并非易事，但成功者将永远衣食无忧。'
      },
      tr: {
          message: "Daha güçlü ve zengin olmak için oyuncuların şehir içinde ve sınırlarının ötesinde NPC canavarlarla savaşması ve yapay zeka cyborg'ları yok etmesi, böylece seviye atlaması ve değerli ganimetler elde etmesi gerekir: silah ve zırhlardan farklı zanaatlarda kullanılabilecek çeşitli kaynaklara kadar. Bu zanaatlarda ustalaşmak güç dengesini sizin lehinize değiştirebilir. En az bir zanaatta ustalaşmak kolay bir iş değildir, ancak başarılı olanlar sonsuza dek iyi bir şekilde karşılanacaktır."
      },
      ar: {
          message: 'لكي تصبح أكثر قوة وثراءً، يحتاج اللاعبون إلى القتال داخل المدينة وخارج حدودها مع الوحوش غير القابلة للعب، والخروج من الذكاء الاصطناعي الآلي، وبالتالي الارتقاء بالمستوى والحصول على جوائز قيمة: من الأسلحة والدروع إلى الموارد المختلفة التي يمكن استخدامها في الحرف المختلفة. يمكن أن يؤدي إتقان هذه الحرف إلى تغيير ميزان القوى لصالحك. أن تصبح بارعًا في حرفة واحدة على الأقل ليست بالمهمة السهلة، لكن من ينجح في ذلك سيحظى بتزويده بموارد جيدة إلى الأبد.'
      },
      es: {
          message: 'Para hacerse más poderoso y rico, el jugador tiene que luchar dentro de la ciudad y más allá de sus fronteras contra monstruos NPC y contra ciborgs IA derrotados, subiendo así de nivel y obteniendo valiosos trofeos: desde armas y armaduras hasta diversos recursos que pueden utilizarse en diferentes artesanías. Dominar estos oficios puede inclinar la balanza del poder a tu favor. Convertirse en maestro de al menos un oficio no es tarea fácil, pero los que lo consigan estarán siempre bien provistos.'
      }
    }),
    useTranslation({
      ru: {
        message: 'Но это еще не все. Мир Doom City максимально приближен к реальной жизни, создавая полноценную "метавселенную" в рамках одного мегаполиса с множеством дополнительных занятий и возможностей: торговля игровыми предметами за криптовалюту, строительство зданий и развитие территорий, открытие модных домов и даже возможность жениться - делают мир динамичным и ярким.',
      },
      en: {
        message: `But that's not all. The world of Doom City is as close to real life as possible, creating a full-fledged "metaverse" within a single metropolis with numerous additional activities and opportunities: trading game items for cryptocurrency, constructing buildings and developing territories, opening fashion houses, and even the possibility of getting married— make the world dynamic and vibrant.`,
      },
      zh: {
          message: '但这还不是全部。末日之城》的世界尽可能地贴近现实生活，在一个单一的大都市中创造了一个完整的 "元宇宙"，并提供了大量额外的活动和机会：用游戏道具换取加密货币、建造建筑和开发领地、开设时装店，甚至还有结婚的可能性，这些都让这个世界充满活力和生机。'
      },
      tr: {
          message: `Ama hepsi bu kadar değil. Doom City'nin dünyası gerçek hayata olabildiğince yakındır ve çok sayıda ek aktivite ve fırsatla tek bir metropol içinde tam teşekküllü bir "metaverse" yaratır: kripto para birimi için oyun öğeleri ticareti, binalar inşa etmek ve bölgeleri geliştirmek, moda evleri açmak ve hatta evlenme olasılığı - dünyayı dinamik ve canlı hale getirir.`
      },
      ar: {
          message: 'ولكن هذا ليس كل شيء. إن عالم Doom City أقرب ما يكون إلى الحياة الواقعية قدر الإمكان، مما يخلق "ميتافيرس" كامل داخل مدينة واحدة مع العديد من الأنشطة والفرص الإضافية: تداول عناصر اللعبة مقابل عملة مشفرة، وتشييد المباني وتطوير المناطق، وفتح بيوت أزياء، وحتى إمكانية الزواج - مما يجعل العالم ديناميكيًا ونابضًا بالحياة.'
      },
      es: {
          message: 'Pero eso no es todo. El mundo de Doom City es lo más parecido posible a la vida real, creando un "metaverso" en toda regla dentro de una única metrópolis con numerosas actividades y oportunidades adicionales: intercambiar objetos del juego por criptodivisas, construir edificios y desarrollar territorios, abrir casas de moda e incluso la posibilidad de casarse hacen que el mundo sea dinámico y vibrante.'
      }
    }),
    useTranslation({
      ru: {
        message: 'В Doom City ваше приключение - это не просто выживание, а путь к богатству и власти. Присоединяйтесь к игре и станьте легендой мегаполиса.',
      },
      en: {
        message: 'In Doom City, your adventure is not just about survival but a journey to wealth and power. Join the game and become a legend of the metropolis.',
      },
      zh: {
          message: '在《末日之城》中，您的冒险不仅是为了生存，更是为了获得财富和权力。加入游戏，成为大都市的传奇人物。'
      },
      tr: {
          message: "Doom City'de maceranız sadece hayatta kalmakla ilgili değil, aynı zamanda zenginlik ve güce doğru bir yolculuk. Oyuna katılın ve metropolün efsanesi olun."
      },
      ar: {
          message: 'في Doom City، لا تقتصر مغامرتك في لعبة Doom City على البقاء على قيد الحياة فحسب، بل هي رحلة نحو الثروة والسلطة. انضم إلى اللعبة وكن أسطورة المدينة.'
      },
      es: {
          message: 'En Doom City, tu aventura no consiste sólo en sobrevivir, sino en un viaje hacia la riqueza y el poder. Únete al juego y conviértete en una leyenda de la metrópolis.'
      }
    }),
]

const about_game = useTranslation({
  ru: {
    message: 'ОБ ИГРЕ',
  },
  en: {
    message: 'ABOUT GAME',
  },
  zh: {
      message: '关于游戏'
  },
  tr: {
      message: 'OYUN HAKKINDA'
  },
  ar: {
      message: 'عن اللعبة'
  },
  es: {
      message: 'ACERCA DEL JUEGO'
  }
})



  return (
    <Body>
      <div id='about_main'>
        <p>{about_game.message}</p>
        <div id='about_description' >
          {content[0].message}
          <br></br><br></br>
          {content[1].message}
          <br></br><br></br>
          {content[2].message}
          <br></br><br></br>
          {content[3].message}
          <br></br><br></br>
          {content[4].message}
          <br></br><br></br>
          {content[5].message}
          <br></br><br></br>
          {content[6].message}

        </div>
      </div>
    </Body>
  )
}

export default AboutGame