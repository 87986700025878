import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import './Rating.css'
import { Body } from '../../components/Body/Body'
import Button from '../../components/Button/Button'
import { useTranslation } from '@plaidev/react-i18n';

const Rating = ({ socket }) => {

    const [rating, setRating] = useState([])
    const [myRang, setMyRang] = useState(0)

    const navigate = useNavigate()

    useEffect(() => {
        window.Telegram.WebApp.BackButton.show()
        window.Telegram.WebApp.onEvent('backButtonClicked', () => navigate("/"))
        socket.emit("get_rating_list", {})
        socket.emit("get_my_rang", { tgid: window.Telegram.WebApp.initDataUnsafe.user.id })
        return () => window.Telegram.WebApp.offEvent('backButtonClicked', () => navigate("/"))
    }, [])

    socket.on("send_rating_list", rating_list => {
        console.log(rating_list)
        setRating(rating_list)
    })

    socket.on("set_my_rang", ({ rating }) => {
        console.log(rating)
        setMyRang(rating.position)
    })

    const your_rang = useTranslation({
        ru: {
          message: 'ВАШ РАНГ',
        },
        en: {
          message: 'YOUR RANK',
        },
        zh: {
            message: '您的排名'
        },
        tr: {
            message: 'SIRANIZ'
        },
        ar: {
            message: 'ترتيبك'
        },
        es: {
            message: 'SU RANGO'
        }
      })

      const own_rating = useTranslation({
        ru: {
          message: 'Личный рейтинг',
        },
        en: {
          message: 'Personal rating',
        },
        zh: {
            message: '个人评级'
        },
        tr: {
            message: 'Kişisel değerlendirme'
        },
        ar: {
            message: 'التقييم الشخصي'
        },
        es: {
            message: 'Calificación personal'
        }
      })

      const band_rating_lt = useTranslation({
        ru: {
          message: 'Рейтинг банд',
        },
        en: {
          message: 'Band rating',
        },
        zh: {
            message: '乐队评级'
        },
        tr: {
            message: 'Bant derecelendirmesi'
        },
        ar: {
            message: 'تصنيف النطاق'
        },
        es: {
            message: 'Clasificación por bandas'
        }
      })

  return (
    <Body>
        <div id='rating_main'>
            <div id='rating_buttons'>
                <Button text={own_rating.message} />
                <Button onClick={() => navigate('/band-rating')} text={band_rating_lt.message}/>
            </div>
            <div id='rating_your_score'>
                <p>{your_rang.message}:</p>
                <p>{myRang}</p>
            </div>
            <div id='rating_list'>
                {
                    Object.values(rating).map(v => {
                        return(
                            <div className='rating_list_item'>
                                <p>{v.position}.</p>
                                <p>{v.name}</p>
                                <p>{v.score}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </Body>
  )
}

export default Rating