import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Body } from '../../components/Body/Body'
import "./Info.css"
import { useTranslation } from '@plaidev/react-i18n';

const Button = ({ link }) => {

  const navigate = useNavigate()

  useEffect(() => {
    window.Telegram.WebApp.BackButton.show()
    window.Telegram.WebApp.onEvent('backButtonClicked', () => navigate("/"))
    return () => window.Telegram.WebApp.offEvent('backButtonClicked', () => navigate("/"))
  }, [])

  const onButtonClick = () => {
    if (link.startsWith("https")) {
      window.Telegram.WebApp.openTelegramLink(link)
    } else {
      navigate(link)
    }
  }

  return(
    <div onClick={onButtonClick} className='info_button'>
      <div className='info_button_s'>
        <div className='info_button_t'>
          <span className='info_button_arrow'></span>
        </div>
      </div>
    </div>
  )
}

const Info = () => {

  const roadmap = useTranslation({
    ru: {
      message: 'ДОРОЖНАЯ КАРТА',
    },
    en: {
      message: 'ROADMAP',
    },
    zh: {
        message: '路线图'
    },
    tr: {
        message: 'YOL HARİTASI'
    },
    ar: {
        message: 'خريطة الطريق'
    },
    es: {
        message: 'MAPA DE CARRERERAS'
    }
  })

  const about_game = useTranslation({
    ru: {
      message: 'ОБ ИГРЕ',
    },
    en: {
      message: 'ABOUT GAME',
    },
    zh: {
        message: '关于游戏'
    },
    tr: {
        message: 'OYUN HAKKINDA'
    },
    ar: {
        message: 'نبذة عن اللعبة'
    },
    es: {
        message: 'SOBRE EL JUEGO'
    }
  })

  const cooperation = useTranslation({
    ru: {
      message: 'СОТРУДНИЧЕСТВО',
    },
    en: {
      message: 'COOPERATION',
    },
    zh: {
        message: '合作'
    },
    tr: {
        message: 'İŞBİRLİĞİ'
    },
    ar: {
        message: 'التعاون'
    },
    es: {
        message: 'COOPERACIÓN'
    }
  })

  const help = useTranslation({
    ru: {
      message: 'ПОМОЩЬ',
    },
    en: {
      message: 'HELP',
    },
    zh: {
        message: '帮助'
    },
    tr: {
        message: 'YARDIM'
    },
    ar: {
        message: 'مساعدة'
    },
    es: {
        message: 'AYUDA'
    }
  })
  
  const data = [
    { name: roadmap.message, link: "/roadmap"},
    { name: about_game.message, link: "/about"},
    { name: "NFT", link: "/nft"},
    { name: "JETTONOMICS", link: "/airdrop"},
    { name: cooperation.message, link: "https://t.me/ForgetTab"},
    { name: help.message, link: "https://t.me/ForgetTab"}
  ]

  return (
    <Body>
        <div id='info_main'>
          {
            Object.values(data).map(v => {
              return (
                <div className='info_row_background'>
                  <div className='info_row'>
                    <div className='info_row_header'>
                      <p>{v.name}</p>
                    </div>
                    <Button link={v.link} />
                  </div>
                </div>
              )
            })
          }
        </div>
    </Body>
  )
}

export default Info