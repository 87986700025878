import {Route, Routes} from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Main from './pages/Main/Main';
import Info from "./pages/Info/Info";
import AboutGame from "./pages/AboutGame/AboutGame";
import Airdrop from "./pages/Airdrop/Airdrop";
import NFT from "./pages/NFT/NFT";
import Roadmap from "./pages/Roadmap/Roadmap";
import Rating from "./pages/Rating/Rating";
import BandRating from "./pages/BandRating/BandRating";
import Boosts from "./pages/Boosts/Boosts";

window.Telegram.WebApp.expand()
window.Telegram.WebApp.setHeaderColor("#506146")

function App({socket}) {
  return (
    <>
        <Routes>
          <Route path="/" element={<Main socket={socket}/>}/>
          <Route path="/info" element={<Info socket={socket}/>}/>
          <Route path="/about" element={<AboutGame socket={socket}/>}/>
          <Route path="/airdrop" element={<Airdrop socket={socket}/>}/>
          <Route path="/nft" element={<NFT socket={socket}/>}/>
          <Route path="/roadmap" element={<Roadmap socket={socket}/>}/>
          <Route path="/rating" element={<Rating socket={socket}/>}/>
          <Route path="/band-rating" element={<BandRating socket={socket}/>}/>
          <Route path="/boost" element={<Boosts socket={socket}/>}/>
        </Routes>
        <ToastContainer hideProgressBar={true} closeButton={false} limit={1} theme="dark" p autoClose={2000} transition={Zoom} position="top-center" />
    </>
  );
}

export default App;
