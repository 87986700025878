import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { I18nProvider, browser, ignorePlaceCode, forcedLocale } from '@plaidev/react-i18n';
import io from 'socket.io-client'
import config from "./config";
import { TonConnectUIProvider } from '@tonconnect/ui-react'

const socket = io.connect(config.socket_addr, {query: `tgid=${window.Telegram.WebApp.initDataUnsafe.user.id}`})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter basename='/main'>
      <I18nProvider
          fallbackLocale="en"
          detectors={[browser, ignorePlaceCode]}
      >
        <TonConnectUIProvider manifestUrl="https://dm-token.ru/main/tonconnect-manifest.json">
          <App socket={socket}/>
        </TonConnectUIProvider>
      </I18nProvider>
    </BrowserRouter>
  </React.StrictMode>
);
