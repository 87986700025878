import React from 'react'
import './Button.css'

const Button = ({ text, onClick, color, svg }) => {
  return (
    <div onClick={onClick} className='button_wrap'>
        <div className={`button ${(color !== undefined) && color}`}>
            <div className={`button_s ${(color) && color}`}>
                <div className={`button_t ${(color) && color}`}>
                    {
                      (svg) ?
                        <img src={svg} alt='svg'/>
                      :
                        <p>{text}</p>
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default Button