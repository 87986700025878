import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Body } from '../../components/Body/Body'
import './Airdrop.css'
import { useTranslation } from '@plaidev/react-i18n';

const Airdrop = () => {

  const navigate = useNavigate()
  
  useEffect(() => {
    window.Telegram.WebApp.BackButton.show()
    window.Telegram.WebApp.onEvent('backButtonClicked', () => navigate("/info"))
    return () => window.Telegram.WebApp.offEvent('backButtonClicked', () => navigate("/info"))
  }, [])

  const data = [
    useTranslation({
      ru: {
        message: 'Общая ограниченная капитализация - 10 000 000 000 000 джеттонов.',
      },
      en: {
        message: 'Total limited emission - 10,000,000,000 Jetton.',
      },
      zh: {
          message: '受限资本总额为 10 000 000 000 000 杰通公司。'
      },
      tr: {
          message: "Toplam kısıtlı sermaye 10 000 000 000 000'dur."
      },
      ar: {
          message: 'إجمالي الرسملة - 10 000 000 000 000 جيتون'
      },
      es: {
          message: 'Capitalización total - 10 000 000 000 000 Jetton'
      }
    }),
    useTranslation({
      ru: {
        message: 'Вознаграждение за стейкинг - 10%',
      },
      en: {
        message: 'Staking rewards - 10%',
      },
      zh: {
          message: '投注奖励 - 10%'
      },
      tr: {
          message: 'Staking ödülleri - %10'
      },
      ar: {
          message: 'مكافآت الرهان - 10%'
      },
      es: {
          message: 'Recompensas de las apuestas - 10%.'
      }
    }),
    useTranslation({
      ru: {
        message: 'Для владельцев NFT - 10%',
      },
      en: {
        message: 'For NFT holders - 10%',
      },
      zh: {
          message: 'NFT 持有者 - 10%'
      },
      tr: {
          message: 'NFT sahipleri için - %10.'
      },
      ar: {
          message: 'لحاملي NFT - 10%.'
      },
      es: {
          message: 'Para titulares de NFT - 10%.'
      }
    }),
    useTranslation({
      ru: {
        message: 'ТОП-10 банд - 10%',
      },
      en: {
        message: 'TOP-10 gangs - 10%',
      },
      zh: {
          message: '十大帮派 - 10%'
      },
      tr: {
          message: 'İlk 10 çete - %10'
      },
      ar: {
          message: 'أفضل 10 عصابات - 10٪'
      },
      es: {
          message: 'TOP-10 bandas - 10%'
      }
    }),
    useTranslation({
      ru: {
        message: 'Ретродроп - 30%',
      },
      en: {
        message: 'Retrodrop - 30%',
      },
      zh: {
          message: 'Retrodrop - 30%'
      },
      tr: {
          message: 'Retrodrop - 30%'
      },
      ar: {
          message: 'ريترودروب - 30%'
      },
      es: {
          message: 'Retrodrop - 30%'
      }
    }),
    useTranslation({
      ru: {
        message: 'Команда - 15% (заморожено на 6 месяцев с последующей линейной разблокировкой на 2,5% в месяц)',
      },
      en: {
        message: 'Team - 15% (frozen for 6 months with further linear unlocking of 2.5% per month)',
      },
      zh: {
          message: '团队 - 15%（冻结 6 个月，以后每月线性解锁 2.5%）。'
      },
      tr: {
          message: 'Ekip - %15 (6 ay boyunca dondurulmuştur ve her ay %2,5 oranında doğrusal olarak açılacaktır)'
      },
      ar: {
          message: 'الفريق - 15% (مجمّد لمدة 6 أشهر مع تحرير خطي آخر بنسبة 2.5% شهريًا)'
      },
      es: {
          message: 'Equipo - 15% (congelado durante 6 meses con un desbloqueo lineal adicional del 2,5% al mes)'
      }
    }),
    useTranslation({
      ru: {
        message: 'Разработка проекта - 25%',
      },
      en: {
        message: 'Project development - 25%',
      },
      zh: {
          message: '项目开发 - 25'
      },
      tr: {
          message: 'Proje geliştirme - %25'
      },
      ar: {
          message: 'تطوير المشروع - 25%'
      },
      es: {
          message: 'Desarrollo de proyectos - 25%.'
      }
    }),
  ]

  return (
    <Body>
      <div id='airdrop_main'>
        <p>JETTONOMICS</p>
        <div id='airdrop_description' >
          {data.map(v => {
            return (
              <p>{v.message}</p>
            )
          })}
        </div>
      </div>
    </Body>
  )
}

export default Airdrop