import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useClickOutside from '../../hooks/useClickOutside'
import config from '../../config'
import './BandRating.css'
import { Body } from '../../components/Body/Body'
import Button from '../../components/Button/Button'
import checkmark from './img/checkmark.svg'
import { useTranslation } from '@plaidev/react-i18n';

const Shield = ({text, children, onClick, onClose, name}) => {

    const ref = useClickOutside(onClose);

    return (
        <div id='shield_background'>
            <div ref={ref} id='shield_wrap'>
                { (name) && <div id='shield_name_container'>
                    <div id='shield_name_container_s'>
                        <div id='shield_name_container_t'>
                            <div id='shield_name_content'>
                                <h3>{name}</h3>
                            </div>
                        </div>
                    </div>
                </div>}
                <div id='shield_main'>
                    <div className='shield_rivets_sideways'>
                        <div className='main_rivet'></div>
                        <div className='main_rivet'></div>
                        <div className='main_rivet'></div>
                        <div className='main_rivet'></div>
                        <div className='main_rivet'></div>
                    </div>
                    <div id='shield_main_center'>
                        <div id='shield_main_center_up'>
                            <div className='main_rivet'></div>
                            <div className='main_rivet'></div>
                            <div className='main_rivet'></div>
                            <div className='main_rivet'></div>
                            <div className='main_rivet'></div>
                        </div>
                        <div id='shield_main_center_content'>
                            <div id='shield_main_center_content_s'>
                                <div id='shield_main_center_content_t'>
                                    {children}
                                </div>
                            </div>
                        </div>
                        <div id='shield_main_center_down'>
                            <div className='main_rivet'></div>
                            <div className='main_rivet'></div>
                        </div>
                    </div>
                    <div className='shield_rivets_sideways'>
                        <div className='main_rivet'></div>
                        <div className='main_rivet'></div>
                        <div className='main_rivet'></div>
                        <div className='main_rivet'></div>
                        <div className='main_rivet'></div>
                    </div>
                </div>
                <Button onClick={onClick} text={text} />
            </div>
        </div>
    )
}

const CreateBand = ({socket, onClose, openBandInfo}) => {

    const [title, setTitle] = useState()

    const onClick = () => {
        if (title.length > 0) {
            socket.emit("create_band", { tgid: window.Telegram.WebApp.initDataUnsafe.user.id, title: title})
            onClose()
            openBandInfo()
        }
    }

    const band_name = useTranslation({
        ru: {
          message: 'НАЗВАНИЕ БАНДЫ',
        },
        en: {
          message: 'BAND NAME',
        },
        zh: {
            message: '帮派名称'
        },
        tr: {
            message: 'ÇETE ADI'
        },
        ar: {
            message: 'اسم العصابة'
        },
        es: {
            message: 'NOMBRE DE LA BANDA'
        }
      })

      const create = useTranslation({
        ru: {
          message: 'Создать',
        },
        en: {
          message: 'Create',
        },
        zh: {
            message: '创建'
        },
        tr: {
            message: 'Oluştur'
        },
        ar: {
            message: 'إنشاء'
        },
        es: {
            message: 'Cree'
        }
      })

    return (
        <Shield onClose={onClose} onClick={onClick} text={create.message}>
            <h4>{band_name.message}</h4>
            <div id='input_main'>
                <input value={title} onChange={(e) => setTitle(e.target.value)}/>
            </div>
        </Shield>
    )
}

const BandInfo = ({socket, onClose, bandId}) => {

    const navigate = useNavigate()

    const [name, setName] = useState('')
    const [members, setMembers] = useState(0)
    const [rating, setRating] = useState(0)
    const [copied, setCopied] = useState(false)

    const invite = useTranslation({
        ru: {
          message: 'ПРИГЛАСИТЬ',
        },
        en: {
          message: 'INVITE',
        },
        zh: {
            message: '邀请'
        },
        tr: {
            message: 'DAVETİYE'
        },
        ar: {
            message: 'دعوة'
        },
        es: {
            message: 'INVITE'
        }
      })

      const memtext = useTranslation({
        ru: {
          message: 'УЧАСТНИКОВ',
        },
        en: {
          message: 'INVITE',
        },
        zh: {
            message: '与会者'
        },
        tr: {
            message: 'KATILIMCILAR'
        },
        ar: {
            message: 'المشاركون'
        },
        es: {
            message: 'PARTICIPANTES'
        }
      })

      const place = useTranslation({
        ru: {
          message: 'МЕСТО',
        },
        en: {
          message: 'PLACE',
        },
        zh: {
            message: '地点'
        },
        tr: {
            message: 'YER'
        },
        ar: {
            message: 'المكان'
        },
        es: {
            message: 'LUGAR'
        }
      })

    useEffect(() => {
        window.Telegram.WebApp.BackButton.show()
        window.Telegram.WebApp.onEvent('backButtonClicked', () => navigate("/"))
        socket.emit("get_rating_list", {})
        return () => window.Telegram.WebApp.offEvent('backButtonClicked', () => navigate("/"))
    }, [])

    useEffect(() => {
        socket.emit("get_band_info", { id: bandId })
    }, [bandId])

    socket.on("set_band_info", band_info => {
        setName(band_info.title)
        setMembers(band_info.players)
        setRating(band_info.position)
    })

    const copytoClipboard = async () => {
        if (!copied) {
            await navigator.clipboard.writeText(config.BOT_URL + `?start=c_${bandId}`)
            setCopied(true)
            setTimeout(() => setCopied(false), 5000)
        }
    }

    return (
        <Shield onClose={onClose} onClick={onClose} name={name} text='OK'>
            <div className='band_info_column'>
                <h4>{invite.message}</h4>
                <Button onClick={copytoClipboard} text={(copied) ? 'Copied' : 'Copy'}/>
            </div>

            <div className='band_info_column'>
                <h4>{memtext.message}</h4>
                <p>{members}</p>
            </div>

            <div className='band_info_column'>
                <h4>{place.message}</h4>
                <p>{rating}</p>
            </div>
        </Shield>
    )
}

const BandRating = ({socket}) => {
    const [showCreateBand, setShowCreateBand] = useState(false)
    const [showBandInfo, setShowBandInfo] = useState(false)
    const [selBand, setSelBand] = useState(0)
    const [bandList, setBandList] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        console.log("Shanged")
        socket.emit("get_rating_bands_list", {})
        socket.emit("get_main_info", { tgid: window.Telegram.WebApp.initDataUnsafe.user.id})
    }, [selBand])

    socket.on("created_band_info", (id) => {
        setSelBand(id.id)
    })
    socket.on("send_main_info", info => {
        if (info.clan > 0) {
            setSelBand(info.clan)
        }
      })

    socket.on("set_rating_bands_list", (band_rating) => setBandList(band_rating))

    const onChangeBand = (e, id) => {
        if (id !== selBand) {
            socket.emit("change_clan", { tgid: window.Telegram.WebApp.initDataUnsafe.user.id, clan: id})
            setSelBand(id)
            console.log(id)
        }
    }

    const own_rating = useTranslation({
        ru: {
          message: 'Личный рейтинг',
        },
        en: {
          message: 'Personal rating',
        },
        zh: {
            message: '个人评级'
        },
        tr: {
            message: 'Kişisel değerlendirme'
        },
        ar: {
            message: 'التقييم الشخصي'
        },
        es: {
            message: 'Calificación personal'
        }
      })

      const band_rating_lt = useTranslation({
        ru: {
          message: 'Рейтинг банд',
        },
        en: {
          message: 'Band rating',
        },
        zh: {
            message: '乐队评级'
        },
        tr: {
            message: 'Bant derecelendirmesi'
        },
        ar: {
            message: 'تصنيف النطاق'
        },
        es: {
            message: 'Clasificación por bandas'
        }
      })

      const band_rating_bt = useTranslation({
        ru: {
          message: 'РЕЙТИНГ БАНД',
        },
        en: {
          message: 'BAND RATING',
        },
        zh: {
            message: '乐队评级'
        },
        tr: {
            message: 'BANT DERECELANDIRMESI'
        },
        ar: {
            message: 'تصنيف الفرقة'
        },
        es: {
            message: 'CLASIFICACIÓN DE LA BANDA'
        }
      })

      const enter_band = useTranslation({
        ru: {
          message: 'Вступить',
        },
        en: {
          message: 'Enter',
        },
        zh: {
            message: '进入'
        },
        tr: {
            message: 'Girin'
        },
        ar: {
            message: 'أدخل'
        },
        es: {
            message: 'Entre en'
        }
      })

      const create_band = useTranslation({
        ru: {
          message: 'Создать банду',
        },
        en: {
          message: 'Create band',
        },
        zh: {
            message: '创建乐队'
        },
        tr: {
            message: 'Grup oluştur'
        },
        ar: {
            message: 'إنشاء فرقة'
        },
        es: {
            message: 'Crear banda'
        }
      })

      const my_band = useTranslation({
        ru: {
          message: 'Моя банда',
        },
        en: {
          message: 'My band',
        },
        zh: {
            message: '我的乐队'
        },
        tr: {
            message: 'Benim grubum'
        },
        ar: {
            message: 'فرقتي'
        },
        es: {
            message: 'Mi banda'
        }
      })

  return (
    <Body>
        <div id='band_rating_main'>
            <div id='band_rating_buttons'>
                <div id='band_rating_buttons_f'>
                    <Button  onClick={() => navigate('/rating')} text={own_rating.message} />
                    <Button text={band_rating_lt.message}/>
                </div>
                <div id='band_rating_buttons_s'>
                    <Button  color={'green'} onClick={() => setShowCreateBand(true)} text={create_band.message}/>
                    { (selBand > 0) && <Button color={'green'} onClick={() => setShowBandInfo(true)} text={my_band.message}/>}
                </div>
            </div>
            <div id='band_header'>
                <p>{band_rating_bt.message}</p>
            </div>
            <div id='band_rating_list'>
                {
                    Object.values(bandList).map(v => {
                        return(
                            <div className='band_rating_list_item'>
                                <p>{v.position}.</p>
                                <p>{v.title}</p>
                                <p>{(v.score) ? v.score : 0}</p>
                                <Button color={(v.id === selBand) && 'green'} onClick={(e) => onChangeBand(e, v.id)} svg={(v.id === selBand) && checkmark} text={enter_band.message} />
                            </div>
                        )
                    })
                }
            </div>
        </div>
        { (showBandInfo) && <BandInfo socket={socket} bandId={selBand} onClose={() => setShowBandInfo(false)} />}
        { (showCreateBand) && <CreateBand 
                                openBandInfo = {() => {setShowBandInfo(true)}}
                                onClose={() => setShowCreateBand(false)}
                                socket = {socket}/>}
    </Body>
  )
}

export default BandRating